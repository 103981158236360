import React from "react"
import Layout from "../components/Layout"

const witchsKnight = () => {
  return (
    <Layout>
      <div align="center" className="header">
        Upcoming game Witch's Knight.
      </div>
      <div align="center">
        <p>
          A 2d game with hand drawn art and gameplay like 2d zelda games where
          you play as an assistant,
        <br></br>
          (whos deeply in debt and a bad contract) of a witch to help run her
          shop by procuring items
        <br></br>
          from the lands and dungeons and deliver them too, do it well or your
          debt might swallow you whole.
        </p>
      </div>
    </Layout>
  )
}

export default witchsKnight
